import React from 'react'

export default function OngoingProjects() {


  return (
    <>


      <div className="container-fluid projects-section text-white pb-5 px-lg-5">
        <div className="row">
          {/* Spotlight Project starts */}
          <div className="col-lg-5 col-xl-5 col-md-12 col-sm-12 p-title pt-5 text-decoration-underline text-center">
            <h1 className="display-5 fw-bold animate__animated animate__fadeInDown">Ongoing Projects</h1>
          </div>
        </div>
        <div className="spotlight-project-section mx-lg-5">

        <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Villa Vizcaya</h1>

            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">

            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject23.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject24.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject25.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject26.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject27.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject28.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject29.jpg"  alt="" />
              </div>
            </div>
          </div>

          <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Riverstone</h1>

            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">

            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject7.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject8.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Vantage</h1>

            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">

            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject30.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject31.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject32.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject33.jpg"  alt="" />
              </div>
            </div>
          </div>


          <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Miami Gardens</h1>

            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject9.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject10.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Tower 350 </h1>

            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject34.jpg"  alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject35.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject36.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject37.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject38.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject39.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col2">
              <div className="spotlight-project rounded-3">
                <img src="Images/Projects/ogproject40.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Windward</h1>
              
            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject19.jpg"  alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject22.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject18.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject20.jpg" alt="" loading='lazy' />
            </div>
          </div>
        </div>

          <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-3">Mason Vero Beach</h1>
              <h4 className="mb-5">Florida</h4>
            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject6.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject5.jpg" alt="" loading='lazy' />
            </div>
          </div>
        </div>

        <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">FB Wynwood</h1>
              
            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject41.jpg"  alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject42.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject43.jpg"  alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject44.jpg"  alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject45.jpg"  alt="" loading='lazy' />
            </div>
          </div>
        </div>
        
        <div className="row gy-3 mt-lg-5">
            <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
              <h1 className="fw-semibold mt-3 mb-5">Aloft</h1>
            </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject15.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject16.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject17.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject18.jpg" alt="" loading='lazy' />
            </div>
          </div>
        </div>

        <div className="row gy-3 mt-lg-5">
          <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
            <h1 className="fw-semibold mt-3 mb-5">Aviara</h1>
          </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject1.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject2.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject11.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject12.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject13.jpg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject14.jpg" alt="" loading='lazy' />
            </div>
          </div>

        </div>

        <div className="row gy-3 mt-lg-5">
          <div className="col-xl-12 col-lg-12 col-sm-12 col1 align-self-center text-center">
            <h1 className="fw-semibold mt-3 mb-3">Azure</h1>
            <h4>Lake Weston</h4>
          </div>
          </div>
          <div className="row gy-3 px-lg-4 mx-lg-5">
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject3.jpeg" alt="" loading='lazy' />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12 col2">
            <div className="spotlight-project rounded-3">
              <img src="Images/Projects/ogproject4.jpeg" alt="" loading='lazy' />
            </div>
          </div>
        </div>

      </div>
    </div >



    </>
  );
}


