import React from 'react'



export default function Contact() {

  var spinner = document.getElementById("spinner")
  function Imageonload() {
    spinner.style.display = "none";
    }

  return (
    <>

      <div className="container-fluid contact-area pb-5">

        <div className="container contact-page ">
          <div className="c-title mb-4 mt-3 text-center">
            <h1 className="fw-semibold text-white text-decoration-underline  animate__animated animate__fadeInDown ">Let's Connect</h1>
          </div>
          <div className="contact-info-2">
            <div className="row text-center">
              <div className="col-lg-4 p-3 animate__animated animate__fadeIn animate__slow  ">
                <i class="bi bi-telephone-fill contact-phone fs-1 c-icon"></i>
                <h4 className="fw-semibold pt-3">Call Today</h4>
                <a href="tel: (561)-629-7749" className="text-decoration-none text-white"><p>(561)-629-7749</p></a>
              </div>
              <div className="col-lg-4 p-3 animate__animated animate__fadeIn animate__slow  ">
                <i class="bi bi-geo-alt-fill contact-address fs-1 c-icon"></i>
                <h4 className="fw-semibold pt-3">Address</h4>
                <a href="https://www.google.com/maps/dir//Wright+Brothers+Contracting+Services,+6715+White+Dr,+Riviera+Beach,+FL+33407,+United+States" className="text-decoration-none text-white" target="blank"><p>6715 White Drive, Riviera Beach , FL, 33407</p>
                </a>
              </div>
              <div className="col-lg-4 p-3 animate__animated animate__fadeIn animate__slow  ">
                <i class="bi bi-envelope-at-fill contact-email fs-1 c-icon"></i>
                <h4 className="fw-semibold pt-3">Email</h4>
                <a href="mailto: info@wrightbrotherscs.com" className="text-decoration-none text-white"><p>info@wrightbrotherscs.com</p></a>
              </div>
              <div className="col-lg-4 mt-5 animate__animated animate__fadeIn  animate__delay-1s">
                <div className="contact-wrapper">
                  <div className="contact-wrapper-title mb-3">
                    <h2 className='mb-5 text-white'>We're ready to help you.</h2>
                    <i class="bi bi-file-earmark-person fs-1 c-icon"></i>
                    <h6 className='mb-3 text-white'>For Full Time Position, please email your Resume to</h6>
                    <button className='submit-button mb-5'><a  href="mailto: hr@wrightbrotherscs.com" className="text-decoration-none"><h6 className='p-2  '>hr@wrightbrotherscs.com</h6></a></button>
                  </div>
                  {/* <div className="contact-form"> */}
                  {/* <form action="https://formspree.io/f/xpzeyvlp" method="POST"> */}
                    {/* <form method="POST">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input class="form-control" type="text" placeholder="Full Name" name="name" required autoComplete="off"  />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input class="form-control" type="email" placeholder="Email Address" name="email" required autoComplete="off" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input class="form-control" type="text" placeholder="Phone No." name="phone"required autoComplete="off" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea name="msg" class="form-control" placeholder="Write your message" required autoComplete="off"></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input class="submit-button" type="submit" value="submit" />
                          </div>
                        </div>
                      </div>
                    </form> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="col-lg-8  map-column animate__animated animate__fadeIn animate__delay-1s">
                <iframe onLoad={Imageonload} title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.0226673965803!2d-80.10810448461534!3d26.775547372426704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8d63ed793ffff%3A0x7a317d6252882045!2sWright%20Brothers%20Contracting%20Services!5e0!3m2!1sen!2sin!4v1680018864607!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>


          </div>








        </div>


      </div>

    </>
  )
}
