import { BrowserRouter, Routes, Route, Navigate, } from 'react-router-dom';
import Home from "./My Components/Home";
import About from "./My Components/About";
import Contact from "./My Components/Contact";
import OngoingProjects from "./My Components/OngoingProjects";
import Navbar from './My Components/Navbar';
import Footer from './My Components/Footer';
import GoToTop from './My Components/GoToTop';
import ScrollToTop from "./My Components/ScrollToTop";
import HomeNavbar from "./My Components/HomeNavbar";
import './My Components/Navbar.css';
import './My Components/HomeNavbar.css';
import './My Components/About.css';
import './My Components/Home.css';
import './My Components/Projects.css';
import './My Components/Footer.css';
import './My Components/Contact.css';
import './My Components/GoToTop.css';
import './App.css';
import React from 'react';
import AllProjects from './My Components/AllProjects';
import ProjectVideos from './My Components/ProjectVideos';
import './My Components/ProjectVideos.css';
import Careers from './My Components/Careers';
import './My Components/Careers.css';



function App() {



  return (

    <>

      <div className="App">




        <BrowserRouter>
          <ScrollToTop />
          <Routes>

            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<><HomeNavbar /><Home /> <Footer /></>} />
            <Route path="/about" element={<><Navbar /><About /> <Footer /></>} />
            {/* <Route path="/services" element={<><Navbar /><Services /> <Footer /></>} /> */}
            <Route path="/allprojects" element={<><Navbar /><AllProjects /> <Footer /></>}/>
            <Route path="/spotlightprojects" element={<><Navbar /><OngoingProjects /> <Footer /></>}/>
            <Route path="/projectvideos" element={<><Navbar /><ProjectVideos /> <Footer /></>}/>
            <Route path="/contact" element={<><Navbar /><Contact /> <Footer /></>} />
            <Route path="/careers" element={<><Navbar /><Careers /> <Footer /></>} />
            <Route path="*" element={<Navigate to="/home" />} />

          </Routes>
        </BrowserRouter>
        <GoToTop />


      </div>

    </>
  );
}


export default App;
