import React, { useState } from 'react'
import { NavLink, } from 'react-router-dom'


export default function Home() {

// Function to close hamburger
const [navOpen, setNavOpen] = useState(false);

function toggleNav() {
  setNavOpen((state) => !state);
}


  var spinner = document.getElementById("spinner")
  function VideoOnLoad() {
    spinner.style.display = "none";
    }


  return (
    <>



      <div className="home-section">

        {/* Video part starts */}

        <div className="home-video w-100 animate__animated animate__fadeIn">
          <video playsInline="playsinline" onLoadedData={VideoOnLoad} autoPlay="autoplay" muted="muted" loop="loop" id="myVideo">
            <source src="Images/Home/homevid.mp4" type="video/mp4" />
          </video>

          {/* Home Text starts */}

          <div className="home-text py-5 animate__animated animate__fadeInLeft">

            <h1 className="fw-semibold mb-5">Leading Commercial HVAC, Plumbing and Electrical Subcontractor in Florida
            </h1>
            <h3>
              Wright Brothers Contracting Services has grown substantially since its humble founding in 2008
            </h3>

          </div>

          {/* Down Button starts */}

          <div className="col-12 text-center down-arrow animate__animated animate__fadeInUp">
          
                <NavLink  reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/about"><i className="bi bi-arrow-down-circle-fill"></i></NavLink>
              
            
          </div>
        </div>

      </div>



    </>
  )
}
