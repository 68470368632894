import React from 'react'

export default function AllProjects() {
  return (
    <div className="container-fluid">

        <div className="container projects-page">
          <div className="row mt-lg-5 mb-3">
            <div className="col-lg-12 col-md-12 col-sm-12 p-title pt-5 pb-2 text-center text-decoration-underline text-white">
              <h1 className=" display-5 fw-bold text-white wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200" >All Projects</h1>
            </div>
          </div>
          {/* Project Filter tab starts */}
          {/* <nav>
            <div className="nav nav-tabs justify-content-center wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200" id="nav-tab" role="tablist">
              <button className="nav-link tab-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
              <button className="nav-link tab-link" id="nav-mechanical-tab" data-bs-toggle="tab" data-bs-target="#nav-mechanical" type="button" role="tab" aria-controls="nav-mechanical" aria-selected="false">Mechanical</button>
              <button className="nav-link tab-link" id="nav-plumbing-tab" data-bs-toggle="tab" data-bs-target="#nav-plumbing" type="button" role="tab" aria-controls="nav-plumbing" aria-selected="false">Plumbing</button>
              <button className="nav-link tab-link" id="nav-electrical-tab" data-bs-toggle="tab" data-bs-target="#nav-electrical" type="button" role="tab" aria-controls="nav-electrical" aria-selected="false">Electrical</button>
              <button className="nav-link tab-link" id="nav-gen-tab" data-bs-toggle="tab" data-bs-target="#nav-gen" type="button" role="tab" aria-controls="nav-gen" aria-selected="false">General Contracting</button>
            </div>
          </nav> */}
          <div className="row mt-4 gy-4 gx-5">
            {/* <div className="tab-content wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex="0">all</div>
              <div className="tab-pane fade" id="nav-mechanical" role="tabpanel" aria-labelledby="nav-mechanical-tab" tabIndex="0">mech</div>
              <div className="tab-pane fade" id="nav-plumbing" role="tabpanel" aria-labelledby="nav-plumbing-tab" tabIndex="0">Plmb</div>
              <div className="tab-pane fade" id="nav-electrical" role="tabpanel" aria-labelledby="nav-electrical-tab" tabIndex="0">Elec</div>
              <div className="tab-pane fade" id="nav-gen" role="tabpanel" aria-labelledby="nav-gen-tab" tabIndex="0">Gen</div>
            </div> */}

            {/* All Projects starts */}
            {/* Project1 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"   >
              <div className="project rounded-3"   >
                <img src="Images/Projects/project1.jpg" alt="" />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Reserve at Vero Beach
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p> 1890 Tatnall Square, Vero Beach 32966
                </p>
              </div>
            </div>

            {/* Project2 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms"  >
              <div className="project rounded-3" >
                <img src="Images/Projects/project2.jpg" alt=""  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Bella Villaggio
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>3770 County Line Road, Tequesta, 33469
                </p>
              </div>
            </div>

            {/* Project3 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms"  >
              <div className="project rounded-3"  >
                <img src="Images/Projects/project3.jpg" alt=""  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>The Addison Singer Island
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>224 Inlet Way, Palm Beach Shores, 33404
                </p>
              </div>
            </div>

            {/* Project4 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3" >
                <img src="Images/Projects/project4.jpg" alt=""  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Village at Banyan Ridge
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>801 N. Cleary Road, West Palm Beach, 33413
                </p>
              </div>
            </div>

            {/* Project5 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms" >
              <div className="project rounded-3" >
                <img src="Images/Projects/project5.jpg" alt=""  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Avenir Club House
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>12255 Avenir Drive, Palm Beach Gardens, FL 33412
                </p>
              </div>
            </div>

            {/* Project6 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms" >
              <div className="project rounded-3">
                <img src="Images/Projects/project6.jpg" alt=""  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Grande Palms Tradition
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>SW Discovery Way, Port St. Lucie
                </p>
              </div>
            </div>

            {/* Project7 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3" >
                <img src="Images/Projects/project7.jpg" alt="" loading='lazy'  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Northside Transit Village IV
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>3100 NW 79th Street, Miami, FL 33147
                </p>
              </div>
            </div>
            {/* Project8 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms" >
              <div className="project rounded-3">
                <img src="Images/Projects/project8.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Saratoga
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>160 NW 7th Street, 10th Floor, Miami, FL 33136
                </p>
              </div>
            </div>

            {/* Project9 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project9.jpg" alt=""loading='lazy'  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Poinciana Crossing
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>1801 SW 1st Avenue, Fort Lauderdale
                </p>
              </div>
            </div>

            {/* Project10 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project10.jpg" alt=""loading='lazy'  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Village at Mangonia Lake
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>2221 N. Australian Ave, West Palm Beach, 33407
                </p>
              </div>
            </div>

            {/* Project11 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms" >
              <div className="project rounded-3">
                <img src="Images/Projects/project11.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>SaRiOne Grill Restaurant
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>7525 W. Hillsborough Ave, Tampa, 33615
                </p>
              </div>
            </div>

            {/* Project12 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project12.jpg" alt=""loading='lazy'  />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Dyson Circle Apartments
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>4695 Dyson Cir, West Palm Beach, FL 33415
                </p>
              </div>
            </div>

            {/* Project13 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project13.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>La Mer Nail Salon & Spa
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>11051 Southern Blvd, Royal Palm Beach, FL 33411
                </p>
              </div>
            </div>

            {/* Project14 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project14.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Northside Transit Village II
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>3181 NW 77th Ave, Miami, FL 33147
                </p>
              </div>
            </div>

            {/* Project15 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms" >
              <div className="project rounded-3">
                <img src="Images/Projects/project15.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Nextgen Nail Salon
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>23001 B State Road 7, West Boca Plaza, Boca Raton, 33428
                </p>
              </div>
            </div>

            {/* Project16 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project16.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>PSL Nail Salon
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>5487 NW St. James Dr., Port St Lucie, 34983
                </p>
              </div>
            </div>

            {/* Project17 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project17.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Bliss Nail Salon
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>5602 PGA Blvd, Palm Beach Gardens, 33418
                </p>
              </div>
            </div>

            {/* Project18 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project18.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>18/8 Fine Men’s Salon at Midtown
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>4787 PGA Blvd, Palm Beach Gardens, 33418
                </p>
              </div>
            </div>

            {/* Project19 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project19.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Atlantic Traditions III Port St Lucie
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>P10297 Village Parkway Dr., Port St Lucie, 34987
                </p>
              </div>
            </div>

            {/* Project20 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project20.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Vietnamese Buddhist Cultural Center
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>2312 SW 127th Ave, Davie 33325
                </p>
              </div>
            </div>

            {/* Project21 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project21.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>NorthWest Gardens V
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>735 NW 11th Ave, Ft Lauderdale 33311
                </p>
              </div>
            </div>

            {/* Project22 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project22.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Merry Place Estates
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>457, 451, 445, 439 Lilac Ct, West Palm Beach, FL 33407
                </p>
              </div>
            </div>

            {/* Project23 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms"  >
              <div className="project rounded-3">
                <img src="Images/Projects/project23.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Atlantic @ Cypress Creek
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>3750 W. Commercial Blvd, Tamarac 33309
                </p>
              </div>
            </div>

            {/* Project24 */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms" >
              <div className="project rounded-3">
                <img src="Images/Projects/project24.jpg" alt="" loading='lazy' />
                <div className="project-details-bottom py-3">
                  <div className="project-info">
                    <h5>Village at Lake Osbourne
                    </h5>
                  </div>
                </div>
              </div>
              <div className="project-details-additional">
                <p>2430 Lake Worth Rd, Lake Worth 33460
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
