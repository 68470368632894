import React from 'react'

export default function Careers() {
  return (
    <>
      <div className="container career-section">

        <div className="row mt-lg-5 mb-3">
          <div className="col-lg-12 col-md-12 col-sm-12 p-title pt-4 pb-2 text-center text-white">
            <h1 className=" display-5 fw-semibold text-white text-decoration-underline wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200" >Join the Team</h1> <h4 className="wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200">We are hiring</h4>
          </div>
        </div>

        {/* Accordion starts */}

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">

            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              <h5 className="fw-semibold">Project Accountant</h5>
              <h6 className="fw-semibold">(Head Office)
              </h6>
            </button>

            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                <h5 className="fw-semibold">HVAC Project Manager</h5>
                <h6 className="fw-semibold">(South Florida)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                <h5 className="fw-semibold">HVAC Supervisor</h5>
                <h6 className="fw-semibold">(South Florida)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                <h5 className="fw-semibold"> Plumbing Foreman</h5>
                <h6 className="fw-semibold">(Tampa)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                <h5 className="fw-semibold">Plumbing Supervisor</h5>
                <h6 className="fw-semibold">(Tampa)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                <h5 className="fw-semibold">Plumbing Foreman</h5>
                <h6 className="fw-semibold">(Fort Mayers)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                <h5 className="fw-semibold">Summer Internships</h5>
                <h6 className="fw-semibold">(Estimation, Project Management ,and Field Engineering, BIM and CAD)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                <h5 className="fw-semibold">Plumbing Project Manager</h5>
                <h6 className="fw-semibold">(Miami)
                </h6>
              </button>
            </h2>
            <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
            </div>
          </div>

          {/* <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                <h5 className="fw-semibold">Director of Operations & Strategy</h5>
                <h6 className="fw-semibold">(Florida)
                </h6>
              </button>
            </h2> */}
            {/* <div id="flush-collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Job details will be posted here.</div>
              <div class="accordion-body">
                <div className="job-posting">
                  <h1>Director of Operations and Strategy</h1>
                  <h2>Wright Brothers Contracting Services, Inc.</h2>
                  <br />
                  <p><strong>Location:</strong> 6715 White Drive, Riviera Beach, FL 33407.  25% travel time to various unanticipated client sites within the United States.</p>
                  <p><strong>Job Type:</strong> Full-time</p>
                  <p><strong>Salary:</strong> From $125,000 to $150,000 per year</p>
                  <br />
                  <h3>Job Duties</h3>
                  <p>Coordinate and manage construction project activities, including planning, budgeting, and reviewing project plans for ongoing and upcoming construction projects involving multi-million-dollar large-scale HVAC, Plumbing, and Electrical systems. Oversee the organization, scheduling, and implementation of projects while maintaining client relationships and supervising operations, procurement, and project management. Contribute to the conceptual development of construction projects and collaborate with specialized construction teams, to ensure successful project completion. Will be primarily focused on the following tasks:</p>
                  <ol>
                    <li>Project Planning and Management. This includes:
                      <ul>
                        <li>Plan, schedule, and oversee construction project activities for ongoing and future upcoming projects.</li>
                        <li>Review the construction and engineering project plans for upcoming projects and identify important technical details required for assessing construction methods and putting up the bid</li>
                        <li>Visit project site locations to identify the project progress and update project teams for better planning.</li>
                      </ul>
                    </li>
                    <br />
                    <li>Operational Oversight and Team Development. This includes:
                      <ul>
                        <li>Plan and oversee construction and engineering operations, procurement, and project management.</li>
                        <li>Review contracts and the scope of work once a project deal is closed, negotiating revisions to contracts with client engineers and subcontractors.</li>
                        <li>Conduct training programs on engineering and construction best practices for new employees and interns.</li>
                      </ul>
                    </li>
                    <br />
                    <li>Budgeting and Financial Management. This includes:
                      <ul>
                        <li>Plan budgets for each project and review the status monthly.</li>
                        <li>Assess construction and engineering methods to be used in different projects and prepare quantity takeoff sheets for estimating the proposal values.</li>
                      </ul>
                    </li>
                    <br />
                    <li>Client Relations and Business Development. This includes:
                      <ul>
                        <li>Maintain a database of potential upcoming projects in the active areas of work.</li>
                        <li>Maintain a good relationship with existing and potential clients to secure future projects.</li>
                        <li>Prequalify the company with new potential clients.</li>
                      </ul>
                    </li>

                  </ol>
                  <br />
                  <h3>Job Requirements</h3>
                  <p>
                    Master's degree in construction management, Civil Engineering, or related field and 12 months (1 year) of experience in Construction Management or any occupations in which required experience was gained.  Work experience must include Interpreting construction plans; Preparing budget estimates and cost tracking reports for construction projects involving HVAC and electrical valued at over $1 million; Planning and coordinating construction projects involving HVAC, electrical, and plumbing valued at over $ 1 million; Assessing the cost-effectiveness of construction plans by using computer models; Using construction management software, including Procore.
                  </p>

                  <div className="col-lg-12 col-md-12 col-sm-12 py-2 px-2 mx-auto text-center " data-wow-offset="200" >
                    <div className="card h-100 career-card py-3 mx-3 mx-auto">
                      <a href="mailto: hr@wrightbrotherscs.com" className="text-decoration-none">
                        <h4 className="fw-semibold">TO APPLY, SEND RESUME TO:</h4>
                        <h5> hr@wrightbrotherscs.com</h5>
                        <p className='text-white'>(please list “Director of Operations and Strategy” in the subject matter)</p>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}


        </div>


        {/* Accordian ends */}

        {/* <nav>
          
          <div className="nav nav-tabs justify-content-center wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200" id="nav-tab" role="tablist">
            <button className="nav-link tab-link active" id="nav-pa-tab" data-bs-toggle="tab" data-bs-target="#nav-pa" type="button" role="tab" aria-controls="nav-pa" aria-selected="true">

              <h5 className="fw-semibold">Project Accountant</h5>
              <h6 className="fw-semibold">Head Office
              </h6>

            </button>
            <button className="nav-link tab-link" id="nav-hpm-tab" data-bs-toggle="tab" data-bs-target="#nav-hpm" type="button" role="tab" aria-controls="nav-hpm" aria-selected="false">

              <h5 className="fw-semibold">HVAC Project Manager</h5>
              <h6 className="fw-semibold">South Florida
              </h6>

            </button>
            <button className="nav-link tab-link" id="nav-hs-tab" data-bs-toggle="tab" data-bs-target="#nav-hs" type="button" role="tab" aria-controls="nav-hs" aria-selected="false">

              <h5 className="fw-semibold">HVAC Supervisor</h5>
              <h6 className="fw-semibold">South Florida
              </h6>

            </button>
            <button className="nav-link tab-link" id="nav-pft-tab" data-bs-toggle="tab" data-bs-target="#nav-pft" type="button" role="tab" aria-controls="nav-pft" aria-selected="false">

              <h5 className="fw-semibold"> Plumbing Foreman</h5>
              <h6 className="fw-semibold">Tampa
              </h6>

            </button>
            <button className="nav-link tab-link" id="nav-ps-tab" data-bs-toggle="tab" data-bs-target="#nav-ps" type="button" role="tab" aria-controls="nav-ps" aria-selected="false">

              <h5 className="fw-semibold">Plumbing Supervisor</h5>
              <h6 className="fw-semibold">Tampa
              </h6>

            </button>

            <button className="nav-link tab-link" id="nav-pff-tab" data-bs-toggle="tab" data-bs-target="#nav-pff" type="button" role="tab" aria-controls="nav-pff" aria-selected="false">

              <h5 className="fw-semibold">Plumbing Foreman</h5>
              <h6 className="fw-semibold">Fort Mayers
              </h6>

            </button>

            <button className="nav-link tab-link" id="nav-si-tab" data-bs-toggle="tab" data-bs-target="#nav-si" type="button" role="tab" aria-controls="nav-si" aria-selected="false">

              <h5 className="fw-semibold">Summer Internships</h5>
              <h6 className="fw-semibold">Estimation, Project Management ,and Field Engineering, BIM and CAD
              </h6>

            </button>

            <button className="nav-link tab-link" id="nav-ppm-tab" data-bs-toggle="tab" data-bs-target="#nav-ppm" type="button" role="tab" aria-controls="nav-ppm" aria-selected="false">

              <h5 className="fw-semibold">Plumbing Project Manager</h5>
              <h6 className="fw-semibold">Miami
              </h6>

            </button>

            <button className="nav-link tab-link" id="nav-dos-tab" data-bs-toggle="tab" data-bs-target="#nav-dos" type="button" role="tab" aria-controls="nav-dos" aria-selected="false">

              <h5 className="fw-semibold">Director of Operations & Strategy</h5>
              <h6 className="fw-semibold">Florida
              </h6>

            </button>
          </div>
        </nav> */}


        {/* <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-pa" role="tabpanel" aria-labelledby="nav-pa-tab" tabIndex="0">pa</div>
          <div className="tab-pane fade" id="nav-hpm" role="tabpanel" aria-labelledby="nav-hpm-tab" tabIndex="0">hpm</div>
          <div className="tab-pane fade" id="nav-hs" role="tabpanel" aria-labelledby="nav-hs-tab" tabIndex="0">hs</div>
          <div className="tab-pane fade" id="nav-pft" role="tabpanel" aria-labelledby="nav-pft-tab" tabIndex="0">pft</div>
          <div className="tab-pane fade" id="nav-pff" role="tabpanel" aria-labelledby="nav-pff-tab" tabIndex="0">pff</div>
          <div className="tab-pane fade" id="nav-ps" role="tabpanel" aria-labelledby="nav-ps-tab" tabIndex="0">ps</div>
          <div className="tab-pane fade" id="nav-si" role="tabpanel" aria-labelledby="nav-si-tab" tabIndex="0">si</div>
          <div className="tab-pane fade" id="nav-ppm" role="tabpanel" aria-labelledby="nav-ppm-tab" tabIndex="0">ppm</div>
          <div className="tab-pane fade" id="nav-dos" role="tabpanel" aria-labelledby="nav-dos-tab" tabIndex="0">

            <div className="job-posting">
            <h1>Director of Operations and Strategy</h1>
            <h2>Wright Brothers Contracting Services, Inc.</h2>
            <br />
            <p><strong>Location:</strong> 6715 White Drive, Riviera Beach, FL 33407.  25% travel time to various unanticipated client sites within the United States.</p>
            <p><strong>Job Type:</strong> Full-time</p>
            <p><strong>Salary:</strong> From $125,000 to $150,000 per year</p>
            <br />
            <h3>Job Duties</h3>
            <p>Coordinate and manage construction project activities, including planning, budgeting, and reviewing project plans for ongoing and upcoming construction projects involving multi-million-dollar large-scale HVAC, Plumbing, and Electrical systems. Oversee the organization, scheduling, and implementation of projects while maintaining client relationships and supervising operations, procurement, and project management. Contribute to the conceptual development of construction projects and collaborate with specialized construction teams, to ensure successful project completion. Will be primarily focused on the following tasks:</p>
            <ol>
              <li>Project Planning and Management. This includes:
                <ul>
                  <li>Plan, schedule, and oversee construction project activities for ongoing and future upcoming projects.</li>
                  <li>Review the construction and engineering project plans for upcoming projects and identify important technical details required for assessing construction methods and putting up the bid</li>
                  <li>Visit project site locations to identify the project progress and update project teams for better planning.</li>
                </ul>
              </li>
              <br />
              <li>Operational Oversight and Team Development. This includes:
                <ul>
                  <li>Plan and oversee construction and engineering operations, procurement, and project management.</li>
                  <li>Review contracts and the scope of work once a project deal is closed, negotiating revisions to contracts with client engineers and subcontractors.</li>
                  <li>Conduct training programs on engineering and construction best practices for new employees and interns.</li>
                </ul>
              </li>
              <br />
              <li>Budgeting and Financial Management. This includes:
                <ul>
                  <li>Plan budgets for each project and review the status monthly.</li>
                  <li>Assess construction and engineering methods to be used in different projects and prepare quantity takeoff sheets for estimating the proposal values.</li>
                </ul>
              </li>
              <br />
              <li>Client Relations and Business Development. This includes:
                <ul>
                  <li>Maintain a database of potential upcoming projects in the active areas of work.</li>
                  <li>Maintain a good relationship with existing and potential clients to secure future projects.</li>
                  <li>Prequalify the company with new potential clients.</li>
                </ul>
              </li>

            </ol>
            <br />
            <h3>Job Requirements</h3>
            <p>
              Master’s degree in construction management, Civil Engineering, or related field and 12 months (1 year) of experience in Construction Management or any occupations in which required experience was gained.  Work experience must include Interpreting construction plans; Preparing budget estimates and cost tracking reports for construction projects involving HVAC and electrical valued at over $1 million; Planning and coordinating construction projects involving HVAC, electrical, and plumbing valued at over $ 1 million; Assessing the cost-effectiveness of construction plans by using computer models; Using construction management software, including Procore.
            </p>

            <div className="col-lg-12 col-md-12 col-sm-12 py-2 px-2 mx-auto text-center " data-wow-offset="200" >
              <div className="card h-100 career-card py-3 mx-3 mx-auto">
                <a href="mailto: hr@wrightbrotherscs.com" className="text-decoration-none">
                  <h4 className="fw-semibold">TO APPLY, SEND RESUME TO:</h4>
                  <h5> hr@wrightbrotherscs.com</h5>
                  <p className='text-white'>(please list “Director of Operations and Strategy” in the subject matter)</p>
                </a>
              </div>

            </div>
          </div>
          </div>
        </div> */}




        {/* <div className="row text-center">

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">HVAC Project Manager</h4>
              <h5 className="fw-semibold">South Florida
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">HVAC Supervisor</h4>
              <h5 className="fw-semibold">South Florida
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold"> Plumbing Foreman</h4>
              <h5 className="fw-semibold">Tampa
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">Plumbing Supervisor</h4>
              <h5 className="fw-semibold">Tampa
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">Plumbing Foreman</h4>
              <h5 className="fw-semibold">Fort Mayers
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">Project Accountant</h4>
              <h5 className="fw-semibold">Head Office
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">Summer Internships</h4>
              <h5 className="fw-semibold">Estimation, Project Management ,and Field Engineering, BIM and CAD
              </h5>

            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
            <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >

              <h4 className="fw-semibold">Plumbing Project Manager</h4>
              <h5 className="fw-semibold">Miami
              </h5>

            </div>
          </div>

          <div className="row mx-auto">


            <div className="col-lg-3 col-md-12 col-sm-12 py-2 px-2 mx-auto " data-wow-offset="200" >
              <div className="card h-100 career-card py-3 mx-3 mx-auto">
                <a href="mailto: hr@wrightbrotherscs.com" className="text-decoration-none">
                  <h4 className="fw-semibold">APPLY NOW</h4>
                  <h5> hr@wrightbrotherscs.com</h5>
                </a>
              </div>

            </div>



          </div>

        </div> */}

      </div>
    </>
  )
}
