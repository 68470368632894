import React from 'react'

export default function About() {



  return (
    <>

      <div className="container-fluid about-section">
        <div className="container about-page pt-5 mb-5">
          <div className="row about-row mt-3 mb-5 mx-auto">

            {/* About Company starts */}

            <div className="col-xl-12 col-lg-12 ">

              <div className="about-content">
                <h1 className="text-white animate__animated animate__fadeInDown">
                  Wright Brothers Contracting Services, Inc
                </h1>
                <p className="py-3">
                  We have been serving South Florida since 2008. Our founder, Yin Kyi, had worked in the industry for years and felt that customers deserved more out of the services being provided. He started Wright Brothers as a way for customers to have the best possible experience when seeking a contractor to perform work for them. Yin has spent years assembling the team of talented contractors, technicians and support staff that Wright Brothers offers. Our primary location is here in the heart of Palm Beach County, but we provide our service between Orlando and Miami. Wright Brothers has served both residential and commercial clients across all of our lines of business. We hold licensing in HVAC, Electrical, Plumbing, Mechanical, and General Contracting in addition to various certifications and accreditations. Our team prides ourselves on offering top-notch service at competitive pricing and having the ability to serve all of our customer’s needs in-house. The company is growing on the ethos of Commitment, Integrity and Reliability. Our team works with sincerity and dedication to meet the schedule of our clients. Yin has a simple philosophy towards the growth of the company : No matter the size of the project, our goal is customer satisfaction.</p>
              </div>
              <div className="row">
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 about-founder">
                  <img src="Images/About/sign3.png"  alt="founder sign" className="img-fluid w-70" />
                </div>
                <div className="col-5 founder-name">
                  <h5>Yin Kyi</h5>
                  <p>Architecture and Founder</p></div>
              </div>
            </div>

          </div>

          {/* About Images starts */}

          <div className="row justify-content-center align-items-center gy-5 gx-5">
            <div className="col-xl-4 col-md-6 col-sm-10 d-flex justify-content-center ">
              <div className="about-image">
                <img src="Images/About/about1.jpg"  alt="aboutimage" className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-10 d-flex justify-content-center">
              <div className="about-image">
                <img src="Images/About/about2.jpg"  alt="aboutimage" className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-10 d-flex justify-content-center">
              <div className="about-image">
                <img src="Images/About/about3.jpg"  alt="aboutimage" className="img-fluid" />
              </div>
            </div>

          </div>

        </div>
      </div>

      <div className="team-section my-5">

        <div className="container team-page">
          <div className="team-title text-center mb-4 mt-4" >
            <h1 className="text-white fw-semibold" >LEADING THE WAY</h1>
            <h3 className="text-white">Meet the people that put their passion first</h3>
          </div>
          <div className="row team-row gy-5 mt-2 text-center" >

            {/* About image carousel starts */}

            <div className="col-xl-12 mb-4">
              <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>

                </div>
                <div className="carousel-inner rounded-4  mx-auto wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200">
                  <div className="carousel-item active c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic8.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                  <div className="carousel-item c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic1.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                  <div className="carousel-item c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic2.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                  <div className="carousel-item c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic3.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                  <div className="carousel-item c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic4.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                  <div className="carousel-item c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic6.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                  <div className="carousel-item c-item" data-bs-interval="1500">
                    <img src="Images/Team/teampic7.jpg"  className="d-block img-fluid w-100 c-img" alt="..." />
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>

            {/* Team Cards starts */}
            {/* Column 1 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" >
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team1.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Yin Kyi</h4>
                <h5 className="fw-semibold">Founder & President
                </h5>
                <p>Yin Kyi is Mechanical Engineer by education. He founded Wright Brothers as a HVAC service and repair company in 2008. Keeping his strong belief in customer satisfaction, the company has contributed to multiple projects in South Florida region and is expanding the boundaries.</p>
                <div className="icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti"></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 2 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col  mx-auto wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"    >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team3.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Vishal Singh Chundawat</h4>
                <h5 className="fw-semibold">Director Operations and Strategy
                </h5>
                <p>
                  Vishal has an educational background in Civil Engineering and Construction Management. He brings with him strong experience and educational background from the construction industry.
                </p>
                <div className="icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti"></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 7 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms" >
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto "    >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team7.png"  alt=""  loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Gary Glica</h4>
                <h5 className="fw-semibold">Operations Manager
                </h5>
                <p>Gary brings over 40 years of management and procurement experience to Wright Brothers Contracting. He has spent several years working as a logistics manager at a large fortune 100 company as well as working on several single family home communities and multi-family projects.</p>
                <div className="icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti"></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 3 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"    >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team6.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Richard Kaplan
                </h4>
                <h5 className="fw-semibold">Project Executive
                </h5>
                <p>
                  Richard is responsible for leading Plumbing Division and oversees multiple projects and project teams. He provides leadership on our projects from the early stages of preconstruction through the project delivery to our clients. Richard brings over twenty-two years of project experience to this role.
                </p>
                <div className="icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti"></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 4
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="200ms">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"    >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/dummy.jpeg"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">
                </h4>
                <h5 className="fw-semibold">
                </h5>
                <p>
                
                </p>
                <div className="icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti"></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div> */}

            {/* Column 5 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200" data-wow-delay="300ms">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"   >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team4.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Henry Florian
                </h4>
                <h5 className="fw-semibold">Service Manager
                </h5>
                <p>
                  Henry is certified technician for install and service. He has been with Wright Brothers for eight years and is very well appreciated by the customers.
                </p>
                <div className="icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti"></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 6 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"   >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team5.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Sean Juska
                </h4>
                <h5 className="fw-semibold">Project Supervisor
                </h5>
                <p>
                  Sean Juska is a highly experienced project management professional. He has been working on HVAC systems for past twenty years. He brings with him strong experience of commercial projects, hotels, multifamily, high rise condominiums and luxury homes.
                </p>
                <div className=" icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti "></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 7 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"   >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team9.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Ryan Partington
                </h4>
                <h5 className="fw-semibold">Electrical Project Manager
                </h5>
                <p>
                  
                </p>
                <div className=" icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti "></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            {/* Column 8 */}
            <div className="col-lg-4 col-md-12 col-sm-12 bg-gradient py-2 px-1 team-col wow animate__animated animate__fadeIn" data-wow-duration="1s" data-wow-offset="200">
              <div className="card h-100 team-card px-4 py-4 mx-3 w-75 mx-auto"   >
                <div className="img-container team-img mx-auto mb-4 p-1">
                  <img src="Images/Team/team8.png"  alt="" loading='lazy' className="img-fluid" />
                </div>
                <h4 className="fw-semibold">Kristel Grimes 
                </h4>
                <h5 className="fw-semibold">Electrical Purchasing and Department Manager
                </h5>
                <p>
                  
                </p>
                <div className=" icons team-icons d-flex justify-content-between w-50 mx-auto">

                  <i className="bi bi-linkedin ti "></i>


                  <i className="bi bi-facebook ti"></i>


                  <i className="bi bi-instagram ti"></i>


                  <i className="bi bi-twitter ti"></i>

                </div>
              </div>
            </div>

            

          </div>
        </div>
      </div>
    </>

  )
}