import React from 'react'

export default function ProjectVideos() {


    return (


        <div className="container-fluid">

            <div className="container projects-page">
                <div className="row mt-lg-5 mb-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 p-title pt-5 pb-2 text-center text-decoration-underline text-white">
                        <h1 className=" display-5 fw-bold text-white wow animate__animated animate__fadeInDown" data-wow-duration="1s" data-wow-offset="200" >Project Videos</h1>
                    </div>
                </div>

                <div className="row mt-4 gy-5 gx-5">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <h3 className='text-white mb-4 text-center'>Vantage Apartments</h3>
                        <video width="624"
                            height="355" controls playsInline="playsinline" className='projectvideos'>
                            <source src="Images/Projects/VantageApartments.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <h3 className='text-white mb-4 text-center'>Tower 350</h3>
                        <video width="624"
                            height="355" controls playsInline="playsinline" className='projectvideos'>
                            <source src="Images/Projects/Tower350.mp4" type="video/mp4" />
                        </video>
                    </div>
                   
                </div>

                <div className="row mt-5 mb-5 gy-5 gx-5">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <h3 className='text-white mb-4 text-center'>Villa Vizcaya</h3>
                        <video width="624"
                            height="355" controls playsInline="playsinline" className='projectvideos'>
                            <source src="Images/Projects/VillaVizcayaFull.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <h3 className='text-white mb-4 text-center'>Volaris Stuart Apartments</h3>
                        <video width="624"
                            height="355" controls playsInline="playsinline" className='projectvideos'>
                            <source src="Images/Projects/Volaris Stuart Apartments1.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>

            </div>
        </div>
    )
}
