import React from 'react'

export default function Footer() {
  return (
    <footer className="container-fluid" >
      <div className="container footer-section mt-3" >
        <div className="row align-items-center" >
          <div className="col-xl-9 footer-bottom" >
            <h6 className="fw-light">
              @{new Date().getFullYear()} Wright Brothers Contracting Services. All Rights Reserved.
            </h6>
          </div>
          <div className="col-xl-3">
            <div className="footer-social-icons">
            <a href="https://www.linkedin.com/company/wright-brothers-contracting-services/"><i className="bi bi-linkedin fi"></i></a>
            <a href="https://www.facebook.com/WrightBrothersCS" target="blank"><i className="bi bi-facebook fi"></i></a>
            <a href="https://www.instagram.com/wrightbrotherscs/" target="blank"><i className="bi bi-instagram fi"></i></a>
            <a href="https://twitter.com/WrightBroCS" target="blank"><i className="bi bi-twitter fi"></i></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
