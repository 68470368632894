
import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'

export default function Navbar() {



  // Function to close hamburger
  const [navOpen, setNavOpen] = useState(false);

  function toggleNav() {
    setNavOpen((state) => !state);
  }

  return (

    <>

      {/* Navbar starts */}

      <nav className="navbar navbar-expand-lg common-navbar fixed-top m-0 p-0">
        <div className="container">

          <button onClick={toggleNav} className={navOpen ? "navbar-toggler" : "navbar-toggler collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <NavLink reloadDocument className="navbar-brand mx-auto p-0 m-0" to="/">
            <img src="Images/Navbar/WBlogo.png" alt="navbarimage" className="nav-logo-color" />
          </NavLink>
          <div className={
            navOpen
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          } id="navbarSupportedContent">

            {/* Navbar List Items start */}

            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/home">HOME</NavLink>
              </li>
              <li className="nav-item">
                <NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/about">ABOUT</NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/services">SERVICES</NavLink>
              </li> */}
              <div className="dropdown">
                <li className="navbar-link fw-bold me-4 text-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  PROJECTS
                </li>
                <div className="dropdown-menu">
                  <li className="nav-item"><NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white mb-3" to="/spotlightprojects" >Ongoing Projects</NavLink></li>
                  <li className="nav-item"><NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white mb-3" to="/allprojects">All Projects</NavLink></li>
                  <li className="nav-item"><NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white" to="/projectvideos">Project Videos</NavLink></li>
                </div>
              </div>
              {/* <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/projects">PORTFOLIO</NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/contact">CONTACT</NavLink>
              </li>
              <li className="nav-item">
                <NavLink reloadDocument onClick={toggleNav} className="nav-link navbar-link fw-bold text-white me-4" to="/careers">CAREERS</NavLink>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    </>
  )
};

